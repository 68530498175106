export default function (name: string): ComputedRef<string> {
  const route = useRoute()

  return computed(() => {
    const v = route.params[name]
    if (!v) {
      return ''
    }

    if (typeof v === 'string') {
      return v
    }

    return v[0] || ''
  })
}
